<template>
<div class="filter filter-choice">
    <!-- {{ input ? input.length : -1 }} ==> {{ output ? output.length : -1 }} -->
    <multiselect 
        v-model="selected" 
        :options="options" 
        :multiple="true"
        placeholder="Оберіть зі списку"
        noOptions="Список пустий"
        selectLabel="✓"
        selectedLabel=""
        deselectLabel="✕"
    > 
    </multiselect>
</div>
</template>

<script>
import { unique } from '../utils/utils';
import Multiselect from 'vue-multiselect'

export default {
    props: ["name", "caption", "input"],
    components: { Multiselect },

    data() {
        return {
            selected: [],
        }
    },

    computed: {
        output() {
            if (!this.input) return null;
            if (!this.selected.length) return this.input;

            return this.input.filter(d => this.selected.indexOf(d[this.name]) >=0 )
        },

        options() {
            if (!this.input) return [];

            return unique(this.input, this.name)
        }
    },

    watch: {
        output(val) {
            this.$emit("change", val)
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-choice {


}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
