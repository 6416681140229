<template>
<multiselect 
    :searchable="false" 
    :show-labels="false" 
    :options="options" 
    :multiple="true" 
    class="simple-select"
    :value="value"
    @input="v => this.$emit('input', v)"
    placeholder="групувати за ... "
    :custom-label="labelingFunction"
    :max="2"
> 
</multiselect>

</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    props: ["options", "value", "labels"], 

    computed: {
        optionToLabel() {
            if (!this.labels) return null;

            return this.options.reduce((o,v,i) => {
                o[v] = this.labels[i];
                return o;
            }, {})
        }
    },

    methods: {
        labelingFunction(option) {
            if (!this.labels) return option;
            return this.optionToLabel[option];
        }
    }
}
</script>

<style lang="scss" scoped>
.simple-select {
    max-width: 22em;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
