<template>
<div class="filter-stack">
    <div class="filter-stack-header">
        <FilterIcon /> <p>Сформуйте дані для графку за допомогою фільтрів:</p>
    </div>

    <Collapsible v-for="(f, index) in filters" :key="index" :caption="f.caption">
        <MonthRangeFilter v-if="f.name === 'date'" :name="f.name" :input="inputs[index]" @change="(val) => change(index, val)" />
        <ChoiceFilter v-else                       :name="f.name" :input="inputs[index]" @change="(val) => change(index, val)" />
    </Collapsible>
</div>    
</template>

<script>
import ChoiceFilter from "./ChoiceFilter";
import Collapsible from "./Collapsible";
import MonthRangeFilter from "./MonthRangeFilter";
import FilterIcon from "./icons/FilterIcon";

export default {
    props: ["input", "filters"],
    components: {
        ChoiceFilter,
        Collapsible,
        FilterIcon,
        MonthRangeFilter,
    },
    
    data() {
        return {
            inputs: [this.input],
        }
    },

    methods: {
        change(index, val) {
            this.$set(this.inputs, index + 1, val)
        }
    },

    computed: {
        output() {
            return this.inputs[this.filters.length]
        }
    },
    watch: {
        input(val){
            this.$set(this.inputs, 0, val);
        },  
        output(val) {
            this.$emit("change", val)
        }
    },
}
</script>

<style lang="scss" scoped>
.filter-stack {
    width: 25em;

    .filter-stack-header {
        display: flex;
        align-items: center;
        p {
            font-size: 10px;
            // color: #777;
            font-weight: 100;
        }
    }
}


</style>