// import * as d3 from 'd3';

function addDays(date, days) {
    let d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
}


export {
    addDays,
}