<template>
  <div :class="`collapsible ${open ? 'is-open' : 'is-closed'}`">
    <div class="header" @click="open = !open">
      <p class="caption">
        {{ caption }}
      </p>
    </div>

    <div class="inner">
        <slot />
    </div>
  </div>
</template>


<script>
export default {
  props: ["caption"],

  data() {
    return {
      open: false,
    }
  },

  methods: {
  
  }
};
</script>


<style lang="scss">

.collapsible {
  height: min-content;
//   margin: 0 0 0 8px;
  padding: 16px;
  border-bottom: 1px solid var(--col-mainblue);
  transition: background-color 0.5s ease-in-out;
  
  &.is-open {
    .inner {
      // max-height: 1000px;
      padding: 16px 0 0 0;
    }

    .header {
      &:before {
        transform: rotate(90deg);
      }
    }
  }

  &.is-closed {
    .inner {
      display: none;
      overflow: hidden;
    }
  }

  &.is-simul {
    .header {
      &:before {
        transform: rotate(90deg);
      }
    }
  }
  
  .header {
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      right: 8px;
      width: 1px;
      height: 16px;
      content: "";
      background-color: #000;
      transition: 0.3s ease-in-out;
    }
    &:after {
      transform: rotate(90deg);
    }
    .caption {
      transition: 0.5s;
      margin: 0;
    }
  }
}

</style>