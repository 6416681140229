// This script sends resize messages to the parent page of the iframe. 
// This is for inserting this app via iframe)

const delay = 1000;

let timer = setInterval(function() {
    window.parent.postMessage(
        {
            __texty_iframe_msg__: true, 
            height: document.documentElement.offsetHeight
        },
        "*"         // set target domain
    )} 
    
, delay)


