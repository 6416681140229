import * as d3 from "d3";

const colorScale = d3.scaleOrdinal()
    .domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
    .range([
        "#004188",
        "#faa627",
        "#479851",
        "#874a97",
        "#f9f871",
        "#ff8071",
        "#ff83d2",
        "#801933",
        "#68a7ff",
        "#808080",
    ])


export {
    colorScale,
}