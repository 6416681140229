<template>
<div class="chart-legend" v-if="options">
    <div v-for="(o, index) in options" :key="composite_key(o.keys)" class="option" >
        <div class="value">{{ composite_key(o.keys) }}</div>
        <div :class="`line bg-${index + 1}`"></div>
    </div>
</div>
</template>

<script>
import { composite_key } from '../utils/aggregator'


export default {
    props: ["options"],

    methods: {
        composite_key(keys) {
            return composite_key(keys);
        } 
    }
}
</script>

<style lang="scss" scoped>
.chart-legend {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1em;
    font-size: 10px;

    background: transparentize($color: white, $amount: 0.5);

    .option {
        display: flex; 
        gap: 0.5em;
        align-items: center;

        .line {
            width: 2rem;
            height: 2px;
            background: solid;
        }
    }
}
</style>