import * as d3 from 'd3';

const parseTime = d3.timeParse("%Y-%m-%d");

const API_ROOT = process.env.VUE_APP_API_ROOT;
const API_MAIN_ENDPOINT = `${API_ROOT}/data/`;
const API_COURSES_ENDPOINT = `${API_ROOT}/courses/`;

const options = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Token 7a89a73d72a12272847fcf4b51aa825d',
    }
}

function addParsedDate(ds) {
    ds.forEach(d => {
        d.date = parseTime(d.month_start_date)
    })
}

async function download_main_data() {
    let response = await fetch(API_MAIN_ENDPOINT, options)
    let result = await response.json()
    addParsedDate(result);
    return result;
}

async function download_courses_data() {
    let response = await fetch(API_COURSES_ENDPOINT, options)
    let data = await response.json()
    console.log(data)
    addParsedDate(data);

    let result = [];
    Array.prototype.push.apply(result, data.map(d => 
        ({
            date: d.date,
            month: d.month,
            month_start_date: d.month_start_date,
            region: d.region, 
            city: d.city,
            zoz: d.zoz,
            active_substance: 'methadon',
            count: d.available_courses ? d.available_courses.methadon || 0 : 0,
            total_count: d.total_courses ? d.total_courses.methadon || 0 : 0,
        })
    ));

    Array.prototype.push.apply(result, data.map(d => 
        ({
            date: d.date,
            month: d.month,
            month_start_date: d.month_start_date,
            region: d.region, 
            city: d.city,
            zoz: d.zoz,
            active_substance: 'buprenorphin',
            count: d.available_courses ? d.available_courses.buprenorphin || 0 : 0,
            total_count: d.total_courses ? d.total_courses.buprenorphin || 0 : 0,
        })
    ));

    return result;
}


function unique(data, field) {
    let map = data
        .map(d => d[field])
        .reduce((o, v) => {
            if (!(v in o)) o[v] = true
            return o;
        }, {})

    return Object.keys(map)
}

export {
    API_MAIN_ENDPOINT, API_COURSES_ENDPOINT,
    download_main_data,
    download_courses_data,
    unique,
}