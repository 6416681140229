<template>
<div class="multiple-line-chart">
    <svg :width="inner_width" :height="inner_height" ref="svg">
        <g :transform="`translate(${margin.left}, ${margin.top})`" ref="g"> 
            <g class="axis x" ref="axis_x" :transform="`translate(0, ${height})`"/>
            <g class="axis y" ref="axis_y" >
                <text 
                    class="axis-caption"
                    transform="rotate(-90)"
                    y="-12"
                    x="-10"
                    dy="0.71em"
                    text-anchor="end"
                >
                    кількість{{ unit_suffix }}
                </text>
            </g>
            <g class="lines" ref="lines"> 
                <!-- <path ref="path" class="stroke stroke-1" v-for="l in datas" :key="l.key" :d="line(l.values)" /> -->
            </g>

            <g class="circles" ref="circles"> </g>
            <g class="tooltip-circles" ref="tooltip_circles"> </g>
        </g>
    </svg>

    <ChartLegend v-if="show_legend" 
        :options="datas" 
        :max_count="10"
        style="pointer-events: none" 
        class="legend" :style="{top: `${margin.top}px`, left: `${margin.left}px`}"
    />

    <ChartTooltip :o="tooltip_object" :position="tooltip_position" 
        :key_to_index="key_to_index"
        class="chart-tooltip" 
        :style="{top: `${margin.top}px`, left: `${margin.left}px`}"
        :unit_suffix="unit_suffix"
    />

</div>
</template>

<script>
import * as d3 from 'd3';
import { flatten_with_key, filter_n_top_lineseries, composite_key } from '../utils/aggregator';
import { addDays } from '../utils/date';
import { colorScale } from '../utils/colors'; 
import ChartLegend from './ChartLegend.vue';
import ChartTooltip from './ChartTooltip.vue';


import uk_UA from './../uk-UA.json'
d3.timeFormatDefaultLocale(uk_UA)

const xTickFormat = d3.timeFormat("%B %y");
const DURATION = 150;

export default {
    props: {
        original_datas: {
            type: Array,
            default: () => []
        },

        aggregation_keys: {
            type: Array,
            default: () => []
        },

        show_legend: {
            type: Boolean,
            default: true,
        },

        unit_suffix: {
            type: String,
            default: "",
        }
    },

    components: {
        ChartLegend,
        ChartTooltip,
    },

    data() {
        return {
            inner_width: 960,
            inner_height: 500,
            margin: { top: 20, right: 30, bottom: 30, left: 40 },

            x: d3.scaleTime(),
            y: d3.scaleLinear(),
            key_to_index: d3.scaleOrdinal().range([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
            line: d3.line()
                .x(d => this.x(d.date))
                .y(d => this.y(d.value)),

            tooltip_object: null,
            tooltip_position: null,
        }
    },

    computed: {
        datas() {return filter_n_top_lineseries(this.original_datas, 10)},
        width() {return this.inner_width - this.margin.left - this.margin.right},
        height() {return this.inner_height - this.margin.top - this.margin.bottom},

        x_range() {return [0, this.width]},
        y_range() {return [this.height, 0]},

        flat_data() {return flatten_with_key(this.datas)},

        x_domain() {
            let extent = d3.extent(this.flat_data, d => d.date)
            extent[0] = addDays(extent[0], -12)
            return extent;
        },

        y_domain() {
            let max = d3.max(this.flat_data, d => d.value)
            let min = d3.min(this.flat_data, d => d.value)
            return [Math.min(0, min) * 1.3, max * 1.1]
        },

        draw() {
            console.log("draw")

            console.log(this.datas)
            console.log(this.flat_data)

            this.x.rangeRound(this.x_range);
            this.y.rangeRound(this.y_range);

            this.x.domain(this.x_domain);
            this.y.domain(this.y_domain);

            this.key_to_index.domain(this.datas.map(d => composite_key(d.keys)));

            let axisX = d3.select(this.$refs.axis_x)
            let axisY = d3.select(this.$refs.axis_y)
            
            axisX
                .transition()
                .duration(DURATION)
                .call(d3.axisBottom(this.x)
                    .ticks(d3.timeMonth)
                    .tickFormat(xTickFormat)
                )
                .call(g => g.select(".domain").remove())


            axisY
                // .transition()
                // .duration(DURATION)
                .call(d3.axisRight(this.y)
                    .ticks(this.height / 40)
                    .tickSize(this.width)
                )
                .call(g => g.select(".domain")
                    .remove())
                .call(g => g.selectAll(".tick:not(:first-of-type) line")
                    .attr("stroke-opacity", 0.1)
                    .attr("stroke-dasharray", "2,2"))
                .call(g => g.selectAll(".tick text")
                    .attr("x", 4)
                    .attr("dy", "-4"))
            
            let paths = d3
                .select(this.$refs.lines)
                .selectAll('path')
                .data(this.datas, ds => composite_key(ds.keys))

            let pathsEnter = paths
                .enter()
                .append('path')
            
            let pathsExit = paths
                .exit().remove();
            
            let scheme = d3.schemeAccent

            let pathsMerge = pathsEnter
                .merge(paths)
                .attr("class", "stroke")
                // .attr("class", (d, i) => `stroke stroke-${i+1}`)
                .style("stroke", (d, i) => colorScale(i))
                .attr("d", ds => this.line(ds.values))


            let circles = d3
                .select(this.$refs.circles)
                .selectAll('circle')
                .data(this.flat_data)

            let circlesEnter = circles
                .enter()
                .append('circle')
            
            let circlesExit = circles
                .exit().remove();

            let circlesMerge = circlesEnter
                .merge(circles)
                .attr("class", 'circle')
                .attr("r", 1.5)
                .attr("cx", d => this.x(d.date))
                .attr("cy", d => this.y(d.value))
                .attr("fill", d => colorScale(this.key_to_index(composite_key(d.keys))))

            let tooltipCircles = d3
                .select(this.$refs.tooltip_circles)
                .selectAll('circle')
                .data(this.flat_data)

            let tooltipCirclesEnter = tooltipCircles
                .enter()
                .append('circle')
            
            let tooltipCirclesExit = tooltipCircles
                .exit().remove();

            let tooltipCirclesMerge = tooltipCirclesEnter
                .merge(tooltipCircles)
                .attr("class", 'circle')
                .attr("r", 5)
                .attr("cx", d => this.x(d.date))
                .attr("cy", d => this.y(d.value))
                .on("mouseenter", this.showTooltip)
                .on("mouseleave", this.hideTooltip)

            return true
        },
    },

    mounted() {
        this.draw
    },

    methods: {
        showTooltip(e, d){
            this.tooltip_position = {x: this.x(d.date), y: this.y(d.value)}
            this.tooltip_object = d;
        },
        hideTooltip() {
            this.tooltip_object = null;
        }


    },

    watch: {
        datas: {
            // immediate: true,
            handler(n, o) {
                console.log("data changed")
                this.draw
            }
        }
    }
}


</script>

<style lang="scss" scoped>
.multiple-line-chart {
    position: relative;

    .lines::v-deep .stroke {
        fill: none;
        stroke-linejoin: round;
        stroke-linecap: round;
        stroke-width: 2;
        stroke-opacity: 0.8;
    }

    .axis.x {}
    
    .axis-caption {
        fill: #777;
    }

    .legend {
        position: absolute;
        margin-left: 4rem;
    }

    .chart-tooltip {
        position: absolute;

        top: 0;
        width: 100%;
        height: 100%;
    }

    .tooltip-circles {
        opacity: 0;
    }

    // .axis-caption {
    //     fill: grey;
    // }
}
</style>
