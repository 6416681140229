<template>
<div class="tabs">
    <div 
        v-for="(o, index) in options" 
        :key="o"
        :class="`tab ${o===value ? 'active': ''}`"  
        @click="$emit('input', o)"   
    >
        {{ labels[index] || o }}
    </div>
</div> 
</template>

<script>
export default {
    props: ["value", "options", "labels"],
}
</script>

<style lang="scss" scoped>
.tabs {
    display: flex;

    .tab {
        border: 1px solid var(--col-mainblue);
        cursor: pointer;
        padding: 0 0.5em;
        line-height: 2.4em;
        border-bottom: none;
        margin-left: -1px;
        font-size: 0.9rem;

        color: var(--col-mainblue);

        &.active, &:hover {
            background-color: var(--col-mainblue);
            color: white;
        }
    }
}
</style>