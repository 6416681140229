<template>
<v-data-table
    :headers="headers"
    :items="dataset"
    :items-per-page="20"
    :footer-props="{
        itemsPerPageOptions: [5, 10,20]
    }"
    :dense="true"
    class="data-table"
>
    <template v-slot:item.date="{ item }">
        <span>{{ item.month }}</span>
    </template>
</v-data-table>    
</template>

<script>
import * as d3 from 'd3'; 
import uk_UA from './../uk-UA.json'

d3.timeFormatDefaultLocale(uk_UA)
const xTickFormat = d3.timeFormat("%B %y");

const KEYS = {
    // month: 'Місяць',
    date: 'Місяць',
    region: 'Регіон',
    city: 'Місто',
    zoz: 'ЗОЗ',
    serial_number: 'Серія',
    medication: 'Препарат',
    count: 'Кількість, мг',
};

export default {
    props: ["input", "headers"],

    // data () {
    //     return {
    //         headers: [
    //             { text: 'Місяць', value: 'month' },
    //             { text: 'Регіон', value: 'region' },
    //             { text: 'Місто', value: 'city' },
    //             { text: 'ЗОЗ', value: 'zoz' },
    //             { text: 'Серія', value: 'serial_number' },
    //             { text: 'Препарат', value: 'medication' },
    //             { text: 'Кількість', value: 'count' },
    //         ],
    //     }
    // },

    computed: {
        dataset() {
            return this.input.map(function(d) {
                // console.log(d)
                return {...d, month: xTickFormat(d.date), count: d.count | d.value, medzakupivli_count: d.medzakupivli_count | d.value2}
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.data-table {


}
</style>

<style>
.v-data-footer__select {
    display: none;
}
</style>