import { utils, writeFileXLSX } from 'xlsx';

function exportXLSX(data) {
  const ws = utils.json_to_sheet(data);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFileXLSX(wb, "export.xlsx");
}

export {
    exportXLSX,
}