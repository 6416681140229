<template>
<div class="chart-tooltip">
    <div v-if="o" class="box" :style="{top: `${position.y + margin_y}px`, left: `${position.x + margin_x}px`}">
        <!-- <div class="date outline"> {{ date }} </div> -->
        <div class="key outline" v-for="key in o.keys" :key="key"> {{ key }}</div>
        <div class="value outline"> {{ o.value }}{{ unit_suffix }}</div>
    </div>
</div>
</template>

<script>
import * as d3 from 'd3'; 
import uk_UA from './../uk-UA.json'
import { colorScale } from '../utils/colors'; 

d3.timeFormatDefaultLocale(uk_UA)
const xTickFormat = d3.timeFormat("%B %y");

export default {
    props: ["o", "position", "key_to_index", "unit_suffix"],
    data() {
        return {
            margin_x: 10,
            margin_y: 10,
        }
    },
    computed: {
        // color() {
        //     if (!this.o) return null;
        //     let index = this.key_to_index(this.o.key);
        //     return colorScale(index);
        // },
        date() {
            if (!this.o) return null;
            return xTickFormat(this.o.date);
        },
    }
}
</script>

<style lang="scss" scoped>
.chart-tooltip {
    pointer-events: none;
    font-size: 10px;

    .box {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.226);
        max-width: 15em;

        padding: 0.3em;
        border-radius: 0.5em;
    }

    .value {
        font-weight: 600;
    }


    .outline {
        text-shadow: 
            -1px -1px 0 #fff, 
            1px -1px 0 #fff, 
            -1px 1px 0 #fff, 
            1px 1px 0 #fff;
    }
}
</style>