import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ua'


Vue.use(VueRouter)
Vue.use(ElementUI, { locale })


import MedicationsPage from './components/MedicationsPage.vue'
import CoursesPage from './components/CoursesPage.vue'

const routes = [
  { path: '/', component: MedicationsPage },
  { path: '/courses', component: CoursesPage }
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
