<template>
    <div class="filter filter-month">
        <el-date-picker
            v-model="range"
            type="monthrange"
            range-separator="--"
            start-placeholder="Перший місяць"
            end-placeholder="Останній місяць"
            size="mini"
        />
            <!-- :unlink-panels="true" -->
    </div>
</template>

<script>
export default {
    props: ["name", "caption", "input"],

    data() {
        return {
            pickerOptions: {

            },
            range: [],
        };
    },

    computed: {
        output() {
            if (!this.input) return null;
            if (!this.range || this.range.length != 2) return this.input;

            let start = this.range[0];
            let end = this.range[1];

            return this.input.filter(d => d.date >= start && d.date <= end)
        },
    },

    watch: {
        output(val) {
            this.$emit("change", val)
        },
    }
};
</script>

<style lang="scss" scoped>
.filter-month {
}
</style>
