import { tidy, arrange, groupBy, summarize, sum } from '@tidyjs/tidy';
import * as d3 from 'd3';


function flatten(datas) {
    return [].concat.apply([], datas.map(ds => ds.values))
}

function flatten_with_key(datas) {
    return [].concat.apply([], datas.map(ds => 
        ds.values.map(v => ({
            keys: ds.keys,
            value: v.value,
            value2: v.value2,
            date: v.date
        }))
    ))
}

function add_max_value_to_lineseries(datas) {
    datas.forEach(ds => ds.max_value = d3.max(ds.values, v => v.value));
    return datas;
}

function filter_n_top_lineseries(datas, n=10) {
    if (!datas) return datas;
    if (datas.length <= n) return datas;
    
    let sorted = [...datas].sort((a, b) => d3.descending(a.max_value, b.max_value))
    return sorted.slice(0, n)
}


function to_aggregated_timeseries(ds, grouping_keys) {
    console.log(ds)

    const keys = [...grouping_keys, "date"];
    const groupby_options_levels = [...Array(grouping_keys.length).fill('entries-object'), 'values'];

    const tidy_grouped = tidy(
        ds, 
        arrange("date"),
        groupBy(
            keys,
            [summarize({ value: sum('count'), value2: sum('medzakupivli_count')})],
            groupBy.levels({ 
                levels: groupby_options_levels,
                single: true, 
            })
        ),
    )

    const grouped = Array.from(walk_groups(tidy_grouped, [], grouping_keys.length));

    const grouped_with_max_value = add_max_value_to_lineseries(grouped);
    console.log(grouped_with_max_value)

    return grouped_with_max_value;
}


function* walk_groups(grouped, prefix_keys=[], level) {
    if (level <= 0) {
        yield {keys: prefix_keys, values: grouped};
        return;
    }

    for (const gr of grouped) {
        yield* walk_groups(gr.values, [...prefix_keys, gr.key], level - 1);
    }
}

function composite_key(keys) {
    return keys.join(", ");
}

export {
    // groupBy,
    // summarize,
    to_aggregated_timeseries,
    flatten,
    flatten_with_key,
    filter_n_top_lineseries,
    composite_key,
}