<template>
<div>
    <header v-if="header">
        <h1 class="title">
            Наявність курсів замісної підтримувальної терапії 
        </h1>
    </header>
    <section class="content main-grid">
        <FilterStack :input="data" @change="(val) => this.filtered = val" :filters="filters" class="filter-stack"/>
        <div class="right-pane">
            <div class="right-pane-header">
              <div />
              <div class="header-buttons">
                  <Tabs v-model="tab" :options="['chart', 'full-table']" :labels="['Графік', 'Таблиця']"/>
                  <DownloadIcon class="download-icon" @click.native="exportXLSX(filtered)"/>
              </div>
            </div>

            <div class="chart-tab" v-if="tab==='chart'">
                <div class="loading" v-if="!aggregated"> <p>Завантаження ...</p> </div>
                <MultipleLineChart :original_datas="aggregated" v-if="aggregated" :show_legend="aggregation_keys.length > 0 && aggregation_keys !== '-'"/>
            </div>
            
            <div class="full-table-tab" v-if="tab==='full-table'">
                <DataTable :input="filtered" :headers="table_headers" />
            </div>

            <!-- <div class="table-tab" v-if="tab==='table'">
                <DataTable :input="flatten" :headers="table_headers" />
            </div> -->

        </div>
    </section>
</div>
</template>

<script>

import * as _ from "../send_resize_msg";
import { download_courses_data } from '../utils/utils';
import { to_aggregated_timeseries, flatten } from '../utils/aggregator';
import { exportXLSX } from '../utils/export';

import FilterStack from "../components/FilterStack";
import MultipleLineChart from '../components/MultipleLineChart.vue';
import SimpleSelect from '../components/SimpleSelect.vue';
import Tabs from '../components/Tabs.vue';
import DataTable from '../components/DataTable.vue';
import DownloadIcon from '../components/icons/DownloadIcon.vue';


export default {
  name: "App",
  components: {
    FilterStack,
    MultipleLineChart,
    SimpleSelect,
    Tabs,
    DataTable,
    DownloadIcon,
  },

  created() {
    this.loadData();
  },

  data: function () {
    return {
        data: [],
        header: !inIframe(),
        filtered: null,
        aggregation_keys: [],
        tab: "chart",
        filters: [
            {name: 'region', caption: "Регіон"},
            {name: 'zoz', caption: "Заклад охорони здоров'я"},
            {name: 'active_substance', caption: "Речовина"},
            {name: 'date', caption: "Час"},
        ],
        table_headers: [
            { text: 'Місяць', value: 'date' },
            { text: 'Регіон', value: 'region' },
            { text: 'Місто', value: 'city' },
            { text: 'ЗОЗ', value: 'zoz' },
            { text: "Речовина", value: 'active_substance' },
            { text: 'Вільні курси', value: 'count' },
            { text: 'Всього курсів', value: 'total_count' },
        ],
    };
  },

  computed: {
    aggregated() {
      if (!this.filtered) return null;
      let aggregated = to_aggregated_timeseries(this.filtered, this.aggregation_keys)
      console.log(flatten(aggregated))
      return aggregated;
    }, 
    
    flatten() {
      if (!this.aggregated) return null;
      console.log(flatten(this.aggregated))
      return flatten(this.aggregated);
    }
  },

  methods: {
    async loadData() {
      let data = await download_courses_data()
      this.data = data;
      console.log(data)
    },

    exportXLSX,
  },
};

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
</script>


<style lang="scss">
.multiselect__tags {
  border-radius: 0;
  border: 1px solid var(--col-lightblue);
  // border-bottom: none;
}

.multiselect__tag {
  background: var(--col-lightblue);
}

.multiselect__content-wrapper {
  border-radius: 0;
  border: 1px solid var(--col-mainblue);
}

.multiselect {
  color: var(--col-mainblue);
}
</style>

